<template>
  <Modal pivacy-modal>
      <h3></h3>
      <h4>개인정보 처리 방침</h4>
      <h3>
        현대제철 &lt;개인정보처리시스템/서비스명&gt; 개인정보 처리방침
      </h3>
      <p>
        ㈜현대제철 AE서비스포탈(이하 ‘현대제철‘)는 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및
        관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다.<br/>
        이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고,
        이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
        수립·공개합니다.
      </p>

      <h3>
        개인정보의 처리 목적
      </h3>
      <p>
        현대제철 AE서비스포탈은 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의
        동의를 받는 등 필요한 조치를 이행할 예정입니다.
      </p>
      <table>
        <colgroup>
          <col style="width: 10%"/>
          <col style="width: 30%"/>
          <col style="width: 30%"/>
          <col style="width: 30%"/>
        </colgroup>
        <thead>
        <tr>
          <th>순번</th>
          <th>개인정보파일의 명칭</th>
          <th>운영근거</th>
          <th>처리목적</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td>H-SOLUTION 회원 가입자 정보</td>
          <td>가입자 정보 제공 동의</td>
          <td>회사 및 이용자별 기술서비스 제공시 본인 식별절차에 이용</td>
        </tr>
        <tr>
          <td>2</td>
          <td>H-SOLUTION Q&A 이용자 정보</td>
          <td>이용자 정보 제공 동의</td>
          <td>고객문의 이용에 따른 답변 회신 및 본인 식별 절차에 이용</td>
        </tr>
        <tr>
          <td>3</td>
          <td>H-CORE 회원 가입자 정보</td>
          <td>가입자 정보 제공 동의</td>
          <td>회사 및 이용자별 기술서비스 제공시 본인 식별절차에 이용</td>
        </tr>
        <tr>
          <td>4</td>
          <td>H-CORE Q&A 이용자 정보</td>
          <td>이용자 정보 제공 동의</td>
          <td>고객문의 이용에 따른 답변 회신 및 본인 식별 절차에 이용</td>
        </tr>
        <tr>
          <td>5</td>
          <td>에너지 강재 회원 가입자 정보</td>
          <td>가입자 정보 제공 동의</td>
          <td>회사 및 이용자별 기술서비스 제공시 본인 식별절차에 이용</td>
        </tr>
        <tr>
          <td>6</td>
          <td>에너지 강재 이용자 정보</td>
          <td>이용자 정보 제공 동의</td>
          <td>고객문의 이용에 따른 답변 회신 및 본인 식별 절차에 이용</td>
        </tr>
        </tbody>
      </table>

      <h3>
        개인정보의 처리 및 보유기간
      </h3>
      <p>
        현대제철은 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 다음과 같이 개인정보를 수집시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
      </p>
      <table>
        <colgroup>
          <col style="width: 10%"/>
          <col style="width: 35.5%"/>
          <col style="width: 35.3%"/>
          <col style="width: 40%"/>
        </colgroup>
        <thead>
        <tr>
          <th>순번</th>
          <th>개인정보파일의 명칭</th>
          <th>운영근거</th>
          <th>보유기간 (목적 달성시)</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td>H-SOLUTION 회원 가입자 정보</td>
          <td>가입자 정보 제공 동의</td>
          <td>탈퇴시</td>
        </tr>
        <tr>
          <td>2</td>
          <td>H-SOLUTION Q&A 이용자 정보</td>
          <td>이용자 정보 제공 동의</td>
          <td>1년</td>
        </tr>
        <tr>
          <td>3</td>
          <td>H-CORE 회원 가입자 정보</td>
          <td>가입자 정보 제공 동의</td>
          <td>탈퇴시</td>
        </tr>
        <tr>
          <td>4</td>
          <td>H-CORE Q&A 이용자 정보</td>
          <td>이용자 정보 제공 동의</td>
          <td>1년</td>
        </tr>
        <tr>
          <td>5</td>
          <td>에너지 강재 회원 가입자 정보</td>
          <td>가입자 정보 제공 동의</td>
          <td>탈퇴시</td>
        </tr>
        <tr>
          <td>6</td>
          <td>에너지 강재 Q&A 이용자 정보</td>
          <td>이용자 정보 제공 동의</td>
          <td>1년</td>
        </tr>
        </tbody>
      </table>

      <h3>
        처리하는 개인정보의 항목
      </h3>
      <p>
        현대제철은 다음의 개인정보 항목을 처리하고 있습니다.
      </p>
      <table>
        <colgroup>
          <col style="width: 10%"/>
          <col style="width: 30%"/>
          <col style="width: 60%"/>
        </colgroup>
        <thead>
        <tr>
          <th>순번</th>
          <th>개인정보파일의 명칭</th>
          <th>개인정보 항목</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td>H-SOLUTION 회원 가입자 정보</td>
          <td>국가, 이름, 이메일, 회사명, 팀명, 전화버호(선택)</td>
        </tr>
        <tr>
          <td>2</td>
          <td>H-SOLUTION Q&A 이용자 정보</td>
          <td>국가, 이름, 이메일, 회사명(선택)</td>
        </tr>
        <tr>
          <td>3</td>
          <td>H-CORE 회원 가입자 정보</td>
          <td>성명, 생년월일, 기관명, 소속, 회사주소, 직위, 전화번호, 휴대폰 번호, 이메일, 중복가입확인정보</td>
        </tr>
        <tr>
          <td>4</td>
          <td>H-CORE 게시판 이용자 정보</td>
          <td>성명, 기관명, 소속, 직위, 전화번호, 휴대폰 번호, 이메일</td>
        </tr>
        <tr>
          <td>5</td>
          <td>에너지 강재 회원 가입자 정보</td>
          <td>이름, 이메일, 회사명, 회사 주소, (회사)전화번호, 휴대전화(선택)</td>
        </tr>
        <tr>
          <td>6</td>
          <td>에너지 강재 Q&A 이용자 정보</td>
          <td>이름, 이메일, 회사명, (회사)전화번호, 회사주소(선택)</td>
        </tr>
        </tbody>
      </table>

      <h3>
        개인정보의 위탁에 관한 사항
      </h3>
      <p>
        현대제철은 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
      </p>
      <table>
        <colgroup>
          <col style="width: 10%"/>
          <col style="width: 18%"/>
          <col style="width: 18%"/>
          <col style="width: 18%"/>
          <col style="width: 18%"/>
          <col style="width: 18%"/>
        </colgroup>
        <thead>
        <tr>
          <th>순번</th>
          <th>수탁업체명</th>
          <th>주소</th>
          <th>전화</th>
          <th>근무시간</th>
          <th>위탁업무</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td>현대오토에버(주)</td>
          <td>서울시 강남구 테헤란로 510</td>
          <td>02-6296-6000</td>
          <td>09:00 ~ 18:00</td>
          <td>AE 시스템 운영</td>
        </tr>
        <tr>
          <td>2</td>
          <td>(주)얼리소프트</td>
          <td>서울시 강남구 논현로 126길 22(논현동) 2층</td>
          <td>02-409-5336</td>
          <td>09:00 ~ 18:00</td>
          <td>현대오토에버 업무 위탁<br/>AE 시스템 운영</td>
        </tr>
        </tbody>
      </table>

      <h3>
        개인정보의 파기 절차 및 파기방법
      </h3>
      <p>
        1.현대제철은 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다.<br/>
        다만, 다른 법률에 따라 보존하여야하는 경우에는 그러하지 않습니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.<br/><br/>
        가. 파기절차 : 보유기간이 경과한 개인정보는 종료일로부터 지체 없이 파기합니다.<br/>
        나. 파기방법<br/>
        1) 전자적 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.<br/>
        2) 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
      </p>

      <h3>
        정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항
      </h3>
      <p>
        1. 정보주체(만 14세 미만인 경우에는 법정대리인을 말함)는 언제든지 현대제철이 보유하고 있는 개인정보에

        대하여 개인정보 열람․정정․삭제․처리정지 요구 등의 권리를 행사할 수 있습니다.<br/>

        2. 제1항에 따른 권리 행사는 하단의 개인정보 책임자의 메일을 통하여 “개인정보 열람·정정·삭제

        ·처리정지 요구”를 통하여 하실 수 있으며, 이에 대해 지체 없이 조치하겠습니다.<br/>

        3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수

        있습니다.<br/>

        4. 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제4항, 제37조 제2항에 의하여 정보주체의

        권리가 제한 될 수 있습니다.<br/>

        5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는

        그 삭제를 요구할 수 없습니다.<br/>

        6. 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가

        본인이거나 정당한 대리인인지를 확인합니다.<br/>
      </p>

      <h3>
        개인정보의 안전성 확보 조치에 관한 사항
      </h3>
      <p>
        현대제철은 「개인정보보호법」 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적
        조치를 하고 있습니다.<br/><br/>


        1. 내부관리계획의 수립 및 시행 : 현대제철의 내부관리계획 수립 및 시행은 현대재철의 내부관리 지침을

        준수하여 시행합니다.<br/>

        2. 개인정보 취급 담당자의 최소화 및 교육 : 개인정보를 취급하는 담당자를 지정하고 최소화 하여 개인정보를

        관리하는 대책을 시행하고 있습니다.<br/>

        3. 개인정보에 대한 접근 제한 : 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경,

        말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여

        외부로부터의 무단 접근을 통제하고 있습니다.<br/>

        4. 접속기록의 보관 및 위변조 방지 : 개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 1년

        이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안 기능을 사용하고 있습니다.<br/>

        5. 개인정보의 암호화 : 이용자의 개인정보는 암호화 되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는

        저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.<br/>

        6. 해킹 등에 대비한 기술적 대책 : 현대제철은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을

        막기 위해 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을

        설치하고 기술적/물리적으로 감시 및 차단하고 있습니다. 또한 네트워크 트래픽의 통제(Monitoring)는 물론

        불법적으로 정보를 변경하는 등의 시도를 탐지하고 있습니다.<br/>

        7. 비인가자에 대한 출입 통제 : 개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로 두고

        이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
      </p>

      <h3>
        개인정보를 자동으로 수집하는 장치의 설치·운영 및 거부에 관한 사항
      </h3>
      <p>
        1. 현대제철은 정보주체의 이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.<br/>

        2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의

        정보이며 이용자들이 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.<br/>

        가. 쿠키의 사용목적 : 이용자의 접속빈도나 방문시간 등을 파악하여 이용자에게 보다 편리한 서비스를 제공하기 위해 사용됩니다.<br/>

        나. 쿠키의 설치·운영 및 거부 : 브라우저 옵션 설정을 통해 쿠키 허용, 쿠키 차단 등 설정을 할 수 있습니다.<br/>

        1) Internet Explorer : 웹브라우저 우측 상단의 도구 메뉴 > 인터넷 옵션 > 개인정보 > 설정 > 고급<br/>

        2) Edge : 웹브라우저 우측 상단 설정 메뉴 > 쿠키 및 사이트 권한 > 쿠키 및 사이트 데이터 관리 및 삭제<br/>

        3) Chrome : 웹브라우저 우측 상단의 설정 메뉴 > 개인정보 및 보안 > 쿠키 및 기타 사이트 데이터<br/>

        다. 쿠키 저장을 거부 또는 차단할 경우 서비스 이용에 어려움이 발생할 수 있습니다.
      </p>

      <h3>
        개인정보 보호책임자에 관한 사항
      </h3>
      <p>
        개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호책임자 및 실무담당자를 지정하고 있습니다.
      </p>
      <table>
        <colgroup>
          <col style="width: 25%"/>
          <col style="width: 25%"/>
          <col style="width: 10%"/>
          <col style="width: 25%"/>
        </colgroup>
        <thead>
        <tr>
          <th>구분</th>
          <th>부서명</th>
          <th>성명</th>
          <th>연락처</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>개인정보 관리책임자</td>
          <td>제품개발센터</td>
          <td>정유동</td>
          <td>N/A</td>
        </tr>
        <tr>
          <td>개인정보 관리담당자</td>
          <td>글로벌자동차기술팀</td>
          <td>전진화</td>
          <td>041-680-8610<br/>jhjeon@hyundai-steel.com</td>
        </tr>
        <tr>
          <td>개인정보 기술책임자</td>
          <td>철강생산시스템실</td>
          <td>정연신</td>
          <td>N/A</td>
        </tr>
        <tr>
          <td>개인정보 기술담당자</td>
          <td>철강공정시스템팀</td>
          <td>황유선</td>
          <td>041-680-1664<br/>yousun@hyundai-steel.com</td>
        </tr>
        <tr>
          <td>개인정보 보호책임자</td>
          <td>정보보안실</td>
          <td>김승회</td>
          <td>N/A</td>
        </tr>
        <tr>
          <td>개인정보 보호담당자</td>
          <td>정보보안기획팀</td>
          <td>이승엽</td>
          <td>031-510-2376<br/>sy21@Hyundai-steel.com</td>
        </tr>
        </tbody>
      </table>

      <h3>
        개인정보의 열람 청구를 접수·처리하는 부서
      </h3>
      <p >
        1. 정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서를 통해 할 수 있습니다.<br/>
      </p>
      <table>
        <colgroup>
          <col style="width: 25%"/>
          <col style="width: 25%"/>
          <col style="width: 10%"/>
          <col style="width: 25%"/>
        </colgroup>
        <thead>
        <tr>
          <th>구분</th>
          <th>부서명</th>
          <th>성명</th>
          <th>연락처</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>개인정보 관리책임자</td>
          <td>제품개발센터</td>
          <td>정유동</td>
          <td>N/A</td>
        </tr>
        <tr>
          <td>개인정보 관리담당자</td>
          <td>글로벌자동차기술팀</td>
          <td>전진화</td>
          <td>041-680-8610<br/>jhjeon@hyundai-steel.com</td>
        </tr>
        </tbody>
      </table>
      <p>
        2. 현대제철은 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
      </p>

      <h3>
        정보주체의 권익침해에 대한 구제방법
      </h3>
      <p>
        1. 정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
      </p>
      <table>
        <colgroup>
          <col style="width: 25%"/>
          <col style="width: 25%"/>
          <col style="width: 25%"/>
          <col style="width: 25%"/>
        </colgroup>
        <thead>
        <tr>
          <th>기관명</th>
          <th>소관업무</th>
          <th>홈페이지</th>
          <th>전화</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>개인정보 침해신고센터</td>
          <td>개인정보 침해사실 신고, 상담 신청</td>
          <td>privacy.kisa.or.kr</td>
          <td>(국번없이) 118</td>
        </tr>
        <tr>
          <td>개인정보 분쟁조정위원회</td>
          <td>분쟁조정신청, 집단분쟁조정(민사적 해결)</td>
          <td>www.kopico.go.kr</td>
          <td>1833-6972</td>
        </tr>
        <tr>
          <td>대검찰청 사이버수사과</td>
          <td>개인정보 침해사실 신고</td>
          <td>www.spo.go.kr</td>
          <td>(국번없이) 1301</td>
        </tr>
        <tr>
          <td>경찰청 사이버수사국</td>
          <td>개인정보 침해사실 신고</td>
          <td>ecrm.cyber.go.kr</td>
          <td>(국번없이) 182</td>
        </tr>
        </tbody>
      </table>
      <p>
        2. 또한, 개인정보의 열람, 정정·삭제, 처리정지 등에 대한 정보주체자의 요구에 대하여 공공기관의 장이 행한 처분
        또는 부작위로 인하여 권리 또는 이익을 침해 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
      </p>
      <table>
        <colgroup>
          <col style="width: 30%"/>
          <col style="width: 25%"/>
          <col style="width: 32.1%"/>
          <col style="width: 25%"/>
        </colgroup>
        <thead>
        <tr>
          <th>기관명</th>
          <th>소관업무</th>
          <th>홈페이지</th>
          <th>전화</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>중앙행정심판위원회</td>
          <td>행정심판</td>
          <td>www.simpan.go.kr</td>
          <td>(국번없이) 110</td>
        </tr>
        </tbody>
      </table>

      <h3>
        개인정보 처리방침의 변경에 관한 사항
      </h3>
      <p>
        1. 이 방침은 2023년 12월 18일부터 시행됩니다.<br/>
        2. 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.
      </p>

      <div class="btn-wrap">
        <a class="btn" href="#none">
          <span>이전 개인정보 처리방침 목록 (List Link)</span>
        </a>
      </div>

  </Modal>
</template>

<script>
import Modal from "@/views/components/modal/Modal";
export default {
  name: "PivacyPolicyModal",
  components: {Modal},
  data() {
    return {
    }
  },
  methods: {
    handleCloseClick() {
      // 클릭 이벤트 핸들러
      this.$emit('resolve');
    }
  },
  mounted() {
    document.querySelector('.close').addEventListener('click', this.handleCloseClick);
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[pivacy-modal] {
  .inner { .t(120); .h(60vh)!important; .scroll; .bgc(#fff); .z(10); .rel;
    h4 {
      .fs(50);.lh(40);.ls(-0.025em);.bold; .w(90%); .tc; margin: auto; .c(#00488d);
    }
    h3 {
      .fs(36); .lh(40); .ls(-0.025em); .w(90%); .bold; .mt(50); .mb(50); .tc;  .tl; .mh-c; .c(#374151);
    }
    p{
      .fs(20); .lh(40); .w(90%); .ib; .tl;
    }
    table{
      .fs(20); .lh(40); .w(90%); .tc; .ib; .-t(#4b5563, 2); .mt(30)
    }
    td, th{
      .-l(#d1d5db);
      .-b(#d1d5db);
    }
    th{
      .bold;
    }
    td:first-child, th:first-child{
      .-l;
    }
    .btn-wrap{
      .wh(90%, 50); .ib; .mv(50);
      .btn{
        .abs; .l(300); .wh(50%, 40); .-a(#d1d5db, 1);
        span{ .fs(30) }
      }
    }
  }
  .close-wrap{ .block !important; .wh(100%, 60); .rel; .mh(auto);
    .close{ .wh(50,50); .abs; .contain('/images/mo/pop-x.png');}
  }
}

@media screen and(min-width:1024px) and (max-width: 1399px) {
  [pivacy-modal] { .fix;
    .inner { .mt(0)!important; .t(50%); .wh(1125,90vh)!important; .max-h(1000); transform: translate(-50%,-50%); .bgc(#fff); .z(10);
      h4 {
        .fs(50);.lh(40);.ls(-0.025em);.bold; .w(90%); .tc; margin: auto; .c(#00488d);
      }
      h3 {
        .fs(36); .lh(40); .ls(-0.025em); .w(90%); .bold; .mt(50); .mb(50); .tc;  .tl; .mh-c; .c(#374151);
      }
      p{
        .fs(20); .lh(40); .w(90%); .ib; .tl;
      }
      table{
        .fs(20); .lh(40); .w(90%); .tc; .ib; .-t(#4b5563, 2); .mt(30)
      }
      td, th{
        .-l(#d1d5db);
        .-b(#d1d5db);
      }
      th{
        .bold;
      }
      td:first-child, th:first-child{
        .-l;
      }
      .btn-wrap{
        .wh(90%, 50); .ib; .mv(50);
        .btn{
          .abs; .l(300); .wh(50%, 40); .-a(#d1d5db, 1);
          span{ .fs(30) }
        }
      }
    }

    .close-wrap{ .block !important; .wh(100%, 60); .rel; .t(12%) !important; .mh(auto);
      .close{ .wh(50,50); .abs; .contain('/images/mo/pop-x.png'); }
    }
  }
}
@media screen and (min-width: 1400px) {
  [pivacy-modal] {.fix;
    .inner { .mt(0) !important;.t(50%);.wh(1125, 90vh) !important;.max-h(1000);transform: translate(-50%, -50%);.bgc(#fff);.z(10);
    }

    .close-wrap{ .block !important; .wh(100%, 60); .rel; .t(5%) !important; .mh(auto);
      .close{ .wh(50,50); .abs; .contain('/images/mo/pop-x.png'); }
    }
  }
}
</style>