<template>
  <div app-footer :class="{'on':zUp}">
    <div class="inner">
<!--      <div class="ft-util">-->
<!--        <RouterLink to="">개인정보 취급방침</RouterLink>-->
<!--        <RouterLink to="">이용 약관</RouterLink>-->
<!--      </div>-->
      <div class="corp-area">
        <h1><RouterLink to="/">HCORE</RouterLink></h1>
        <div class="ft-sites">
          <div class="e-business" :class="{'on':eOpen}">
            <a @click="eSiteOpen">E-Business</a>
            <ul @mouseleave="eSiteClose">
              <li v-for="business in businessLink" :key="business.business"><a :href="business.link" target="_blank">{{ business.nm }}</a></li>
            </ul>
          </div>
          <div class="family-site" :class="{'on':fOpen}">
            <a  @click="fSiteOpen">FAMILY SITE</a>
            <ul @mouseleave="fSiteClose">
              <li v-for="family in familyLink" :key="family.family" :class="family.type"><a :href="family.link === '' ? false : family.link" target="_blank">{{ family.nm }}</a></li>
            </ul>
          </div>
          <span class="dim" @click="familySiteClose"></span>
        </div>
      </div>
      <address>경기도 성남시 분당구 분당내곡로 117, 그레이츠 판교(GREITS PANGYO) 9층<i class="web">|</i> <br class="mo"> 사업자등록번호 : 121-81-10385 <br class="mo"><i class="web">|</i>대표전화 : <a href="tel:031-510-2114">031-510-2114</a><i>|</i>FAX : 031-510-2150</address>
      <p class="copy">COPYRIGHT(C) HYUNDAI STEEL. ALL RIGHTS RESERVED.</p>
      <p class="copy pivacy-btn" @click="PivacyPolicyPopOpen">개인정보 처리방침</p>

      <a class="top-go" @click="goTop">상단으로가기</a>
    </div>
  </div>
</template>

<script>
import siteLink from "@/data/siteLink";
import PivacyPolicyModal from "@/views/components/modal/PivacyPolicyModal";

export default {
  name: "AppFooter",
  data() {
    return {
      eOpen: false,
      fOpen: false,
      zUp: false,
    }
  },
  watch: {
    $route() { this.eOpen = false; this.fOpen = false; }
  },
  computed: {
    businessLink() {
      return siteLink.business
    },
    familyLink() {
      return siteLink.family
    }
  },
  methods: {
    eSiteOpen() {
      this.eOpen = !this.eOpen;
      this.fOpen = false;
    },
    fSiteOpen() {
      this.fOpen = !this.fOpen;
      this.eOpen = false;
      this.zUp = !this.zUp;
    },
    goTop() {
      window.scrollTo({top:0, behavior: "smooth"});
    },
    eSiteClose() {
      this.eOpen = false;
    },
    fSiteClose() {
      this.fOpen = false;
    },
    familySiteClose() {
      this.eSiteClose();
      this.fSiteClose();
    },
    PivacyPolicyPopOpen() {
      this.$modal(PivacyPolicyModal);
    },
  }
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[app-footer] { .fs(0); .bgc(#fff); .rel; z-index: 1; .-t(@c-black);
  .inner { .tl; .pb(40); .-box;
    > div { .pl(30); .-box; }
    .ft-util { .h(80); .-t(@c-black); .-b(@c-black);
      a { .fs(24); .lh(80); .ls(-0.025em); .ib; .vam; .mr(60); .rel;
        &:after { .cnt; .wh(1,26); .abs; .rt(-30,25); .bgc(@c-black); }
        &:last-child:after { .hide; }
      }
    }
    .corp-area { .p(40,30,27); .clear;
      h1 { .wh(147,29); .contain('/images/mo/logo-blue2.png'); .ib; .mt(11); }
      .ft-sites { .fr;
        > div { .wh(250,40); .ib; .vam; .-a(@c-black); .ml(8); .rel; .-box; overflow: hidden;
          > a { .fs(20); .lh(40); .tl; .f; .ib; .rel; .pl(20); .-box; z-index: 2; .bgc(#fff);
            &:after { .cnt; .wh(14,9); .contain('/images/mo/ft-arrow-down.png'); .abs; .rt(12,15); pointer-events: none; }
          }
          ul { .w(250); .p(10,0); .-box; .ml(-2); .-a(@c-black); .-b; z-index: 5; .bgc(#fff); transform: translateY(0); .rel;
            li { .fs(20); .lh(40); .pl(20); .-box; .ls(-0.025em);
              &.tit { .bold; .c(#333); .pl(15); .mt(10); }
            }
          }
          &.on { overflow: visible;
            a:after { transform: rotate(180deg); }
            ul { transform: translateY(-121%); z-index: 3; }
            &.family-site ul { transform: translateY(-101.9%); z-index: 3; }
          }
        }
        .dim { .fix; .f; .lt; .-a; pointer-events: none; }
        .e-business.on + .family-site + .dim, .family-site.on + .dim { pointer-events: inherit; }
      }
    }
    address, .copy { .fs(20); .lh(30); .pl(28); .-box; }
    address i { .m(0,20); }
    .copy { .mt(4); }
    .top-go { .fs(0); .wh(109,109); .contain('/images/mo/top-go.png'); .abs; .rt(16,-70); z-index: 2; }
  }
  &.on {z-index: 2;}
  .pivacy-btn{
    cursor: pointer;
    text-decoration: underline;
  }
}

@media screen and(min-width:1240px) {
  [app-footer] { .-t(@c-black); .h(266);
    .inner { .w(1400); .hf; .mh-c; .rel;
      &:after { .cnt; .wh(1,100%); .bgc(@c-black); .abs; .lt(50%,0); .ml(430); }
      .ft-util { height:auto; .abs; .lt(50%,40); .-t; .-b; .w(220); .p(0); .clear; .ml(470);
        a { .fs(16); .lh(30); .mr(0); .rel;
          &:after { .cnt; .wh(1,18); .abs; .rt(-22,5); .bgc(@c-black); }
          &:last-child { .fr; }
        }
      }
      .corp-area { .p(78,0,27); .-box; .tl;
        h1 { .mt(0); }
        .ft-sites { float: none; .abs; .lt(50%,103); .ml(470);
          > div { .wh(220,28); .block; .ml(0); .mb(20);
            > a { .fs(14); .lh(28); .pl(28);
              &:after { .cnt; .rt(12,10); }
            }
            ul { .w(220); .p(10,0); .ml(-1px);
              li { .fs(14); .lh(30); .p(0,26); .-box;
                &:hover { .bgc(#efefef); }
                &.tit:hover { background: none; }
              }
            }
            &.on {
              ul { transform: translateY(-119.5%); }
              &.family-site ul { transform: translateY(-101.8%); }
            }
          }
        }
      }
      address, .copy { .fs(14); .pl(0);}
      .top-go { .fs(0); .wh(108,108); .contain('/images/mo/top-go.png'); .abs; .lt(50%,-70); .ml(710); z-index: 2; }
    }
  }
  .pivacy-btn{
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>